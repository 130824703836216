<template>
  <gl-modal
    v-bind="$attrs"
    :capitalize-title="true"
    full-buttons
    submit-title="Leave"
    title="Are you sure you want to leave?"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('submit')"
  >
    <div>Case has not been saved and all data in the current graph will be lost.</div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
}
</script>
