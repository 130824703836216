<template>
  <div class="new-filter-token">
    <div class="new-filter-token__left">
      <vSelect
        v-model="activeToken"
        class="new-filter-token__select"
        :class="{ 'new-filter-token__select--no-cursor' : dropdownOrView}"
        :clearable="false"
        :disabled="isLoading"
        label="symbol"
        :no-drop="dropdownOrView"
        :options="options"
        placeholder="Select Token"
        :searchable="false"
        @input="newTokenSelect"
      >
        <template v-slot:selected-option="{ symbol, icon, name }">
          <template v-if="dropdownOrView && !icon">
            <GlBlockchainIcon 
              :blockchain-icon="symbol"
            />
          </template>
          <template v-else>
            <GlImg
              v-if="icon"
              :src-val="icon"
            />
          </template>
          <div class="new-filter-token__select-text">
            <div class="new-filter-token__select-name">
              {{ name }}
            </div>
            <div class="new-filter-token__select-symbol">
              {{ symbol }}
            </div>
          </div>
        </template>
        <template v-slot:option="option">
          <GlImg
            v-if="option.icon"
            :src-val="option.icon"
          />
          <div class="new-filter-token__select-text">
            <div class="new-filter-token__select-name">
              {{ option.name }}
            </div>
            <div class="new-filter-token__select-symbol">
              {{ option.symbol }}
            </div>
          </div>
        </template>
      </vSelect>
    </div>
    <div class="new-filter-token__right">
      <div class="new-filter-token__balance">
        {{ balance }}
      </div>
      <div class="new-filter-token__price">
        {{ price }}
        <gl-icon
          v-if="price && priceTimestamp"
          v-popover:tooltip.top="`${priceMessage('current', priceTimestamp)}`"
          class="info-block-rate-icon"
          :height="11"
          name="info"
          style="min-width: 16px"
          :width="11"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import vSelect from "vue-select"
import GlImg from "@/components/gl-img.vue"
import GlBlockchainIcon from '@/components/gl-blockchain-icon'
import GlIcon from '@/components/gl-icon'

import { priceMessage } from "@/utils/format-by-price"

export default {
  name: 'NewTokenSelect',
  components: {
    GlImg,
    vSelect,
    GlBlockchainIcon,
    GlIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    balance: {
      type: String,
      default: '',
    },
    price: {
      type: String,
      default: '',
    },
    priceTimestamp: {
      type: [String, Number],
      default: null,
    },
    dropdownOrView: {
      type: Boolean,
      default: false,
    },
    token: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      activeToken: { ...this.token },
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
  },
  watch: {
    token(newToken) {
      if (!Object.prototype.hasOwnProperty.call(newToken, 'name')) {
        newToken.name = this.coinData.blockchain
      }
      this.activeToken = { ...newToken }
    },
  },
  methods: {
    priceMessage,
    newTokenSelect() {
      this.$emit('newTokenChange', this.activeToken)
    },
  }
}
</script>

<style scoped>
.new-filter-token {
  display: flex;
  gap: 2px;
  height: 56px;
}
.new-filter-token__left {
  flex: 1 0 50%;
  max-width: 50%;
  background-color: var(--menu-background);
  border-radius: 12px 0 0 12px;
}

.new-filter-token__select {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.new-filter-token__select::v-deep .vs__dropdown-toggle {
  height: 100%;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0 12px;
}
.new-filter-token__select--no-cursor::v-deep .vs__dropdown-toggle,
.new-filter-token__select--no-cursor::v-deep .vs__dropdown-toggle * {
  cursor: default;
}
.new-filter-token__select::v-deep .vs__selected-options {
  padding: 0;
  flex-wrap: nowrap;
}
.new-filter-token__select::v-deep .vs__selected {
  height: 100%;
  gap: 8px;
  margin: 0;
  padding: 0 8px 0 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  color: var(--space-cadet);
  position: relative !important;
}
.new-filter-token__select::v-deep .vs__actions {
  padding: 0;
}
.new-filter-token__select::v-deep .vs__dropdown-menu {
  padding: 0;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 10px 21px 0px #00000030;
}
.new-filter-token__select::v-deep .vs__dropdown-menu::-webkit-scrollbar {
  width: 6px;
}
.new-filter-token__select::v-deep .vs__dropdown-option {
  gap: 8px;
  padding: 12px 16px !important;
}
.new-filter-token__select-name {
  max-height: 36px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--space-cadet);
  overflow: hidden;
}
.new-filter-token__select-symbol {
  max-height: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-text);
  overflow: hidden;
}
.new-filter-token__select::v-deep .vs__dropdown-option .new-filter-token__select-text {
  max-width: calc(100% - 28px);
}
.new-filter-token__select::v-deep .vs__dropdown-option .new-filter-token__select-name,
.new-filter-token__select::v-deep .vs__dropdown-option .new-filter-token__select-symbol {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.new-filter-token__right {
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 0 4px 16px;
  background-color: var(--menu-background);
  border-radius: 0 12px 12px 0;
}
.new-filter-token__balance {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--space-cadet);
}
.new-filter-token__price {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-text);
}
</style>