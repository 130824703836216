<template>
  <gl-modal
    v-bind="$attrs"
    :capitalize-title="true"
    full-buttons
    submit-title="Change"
    title="Are you sure you want to change the selected blockchain?"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('submit')"
  >
    <div>Changing the blockchain will reset and clear all data in the current graph.</div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
}
</script>
